/* category.css */

.Categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Category-item {
    text-align: center;
    margin: 10px;
    width: calc(30% - 20px);
    /* 6 categories in a row, adjust margins accordingly */
    max-width: 400px;
    /* Adjust the maximum width as needed */
    box-sizing: border-box;
    /* Ensure padding and borders are included in the width */
}

.Category-item img {
    width: 100%;
    /* Adjust to make the image responsive */
    max-width: 400px;
    /* Adjust the maximum width as needed */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 10%;
}

.Categories {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8%;
}

@media screen and (max-width: 767px) {
    .Categories {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8%;
    }

    .Category-item {
        width: 100%;
        /* Adjust to full width on small screens */
        max-width: none;
        /* Remove max-width on small screens */
    }
}

.MainM {
    display: flex;
    flex-direction: row;
}

.Main {
    display: flex;
    justify-content: space-between;
}

.MainDiv {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 5%;
}

.insideDiv1 {
    display: flex;
    flex-direction: column;
    background-color: red;
    height: 400px;
    width: 400px;
}

.insideDiv2 {
    display: flex;
    flex-direction: column;
    background-color: blue;
    height: 400px;
    width: 400px;
}

.insideDiv3 {
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 400px;
    width: 400px;
}

.insideDiv4 {
    display: flex;
    flex-direction: column;
    background-color: pink;
    height: 400px;
    width: 400px;
}

.MainDiv5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin: 5%;
}

.MainDiv3 {
    display: flex;
    margin: 5%;
    font-size: 16px;
    color: #333;
    /* Set your desired text color */
    padding: 20px;
    background-color: #f0f0f0;
    /* Set your desired background color */
    border: 1px solid #ccc;
    /* Add a border for better visibility */
    max-width: 100%;
    /* Ensures the wrapper doesn't exceed the width of its container */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

.button-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 40px;
    margin: 5%;
}

button {
    /* Your button styles */
    padding: 12px 24px;
    font-size: 18px;
    background-color: #3498db;
    color: #fff;
    border: 2px solid #2980b9;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 50px;
    width: 180px;
}

.buttonGap {
    gap: 50px;
}

@media screen and (max-width: 768px) {
    .Main {
        flex-direction: column;
    }

    .MainDiv {
        margin: 3%;
    }

    .MainDiv5 {
        display: flex;
        flex-direction: column;

    }


    .insideDiv1,
    .insideDiv2,
    .insideDiv3,
    .insideDiv4 {
        height: 300px;
        /* Adjust height for smaller screens */
    }

    .button-grid {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }

    button {
        padding: 10px 15px;
        font-size: 16px;
    }
}
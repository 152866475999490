/* .products {
    display: flex;
    flex-direction: row;
} */

.categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8%;
}

@media screen and (max-width:767px) {
    .categories {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20%;
    }
}
/* Add these styles to your existing CSS */

/* Style inputs */
input[type="text"],
select,
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

/* Style submit button */
input[type="submit"] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: #45a049;
}

/* Style the container/contact section */
.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    /* Increase padding for better spacing */
}

/* Style form labels */
form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

/* Style form input fields */
form input[type="text"],
form select,
form textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Style form submit button */
form input[type="submit"] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

form input[type="submit"]:hover {
    background-color: #45a049;
}

/* Create two columns that float next to each other */
.column {
    float: left;
    width: 50%;
    margin-top: 6px;
    padding: 20px;
    box-sizing: border-box;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

    .column,
    input[type="submit"] {
        width: 100%;
        margin-top: 0;
    }
}

.add {
    display: flex;
    align-items: center;
    justify-content: center;
}
/* main.css */

.main {
    /* Add any general styles for the main container */
}

.mainImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

@media screen and (max-width:767px) {
    .mainImage {
        margin-top: 20%;
    }
}

.mainImage img {
    max-width: 100%;
    height: auto;
}

@media screen and (max-width:767px) {
    .mainImage img {
        max-width: 100%;
        height: 200px;
    }
}


.diveder {
    width: 100%;
    margin: 2%;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgb(90, 27, 27);
    border: 1px solid white;
    border-radius: 8px;
    width: 100%;
    height: auto;

    /* Additional styles for card appearance */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    /* Add a smooth transition for box-shadow */
}

.container:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
/* category.css */

.categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.category-item {
    text-align: center;
    margin: 10px;
    width: calc(30% - 20px);
    /* 6 categories in a row, adjust margins accordingly */
    max-width: 400px;
    /* Adjust the maximum width as needed */
    box-sizing: border-box;
    /* Ensure padding and borders are included in the width */
}

.category-item img {
    width: 100px;
    height: 100px;
    border-radius: 10%;
}

@media screen and (max-width: 767px) {
    .categories-container {
        justify-content: center;
        /* Center items on small screens */
    }

    .category-item {
        width: calc(50% - 20px);
        /* 2 categories in a row on small screens, adjust margins accordingly */
    }
}